<template>
    <router-view />
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

const Web3 = require('web3');
const axios = require('axios');

export default {
    components: {},
    data() {
        return {
            s500: false,
            rpc: '',
            time: 0,
        };
    },
    computed: {
        ...mapGetters({
            isNetWork: 'contract/isNetWork',
        }),
        ChainID() {
            if (this.isNetWork === 'testnet') {
                return '97';
            }
            return '56';
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        async fetchChain(baseURL) {
            const rpcBody = JSON.stringify({
                jsonrpc: '2.0',
                method: 'eth_getBlockByNumber',
                params: ['latest', false],
                id: 1,
            });

            try {
                const API = axios.create({
                    baseURL,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                API.interceptors.request.use((config) => {
                    const newConfig = config;
                    newConfig.requestStart = Date.now();
                    return newConfig;
                });

                API.interceptors.response.use(
                    (res) => {
                        const response = res;
                        response.latency = Date.now() - res.config.requestStart;
                        return response;
                    },
                    function (error) {
                        return Promise.reject(error);
                    },
                );

                const { data, latency } = await API.post('', rpcBody);

                return { ...data, latency };
            } catch (error) {
                return null;
            }
        },
        async getRpcList() {
            const self = this;
            const chains = await axios.get(
                'https://chainid.network/chains.json',
            );
            if (chains.data) {
                const chain = chains.data.find(
                    (c) =>
                        c.chainId?.toString() === self.ChainID ||
                        c.chainId?.toString() ===
                            Object.entries(self.ChainID).find(
                                ([, name]) => self.ChainID === name,
                            )?.[0] ||
                        c.name.toLowerCase() ===
                            self.ChainID.toLowerCase().split('%20').join(' '),
                );
                if (chain && chain.rpc) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const rpc of chain.rpc) {
                        new Promise((resolve) => {
                            resolve(self.fetchChain(rpc));
                        }).then((value) => {
                            if (value) {
                                self.$store.state.contract.provider.link = rpc;
                                if (
                                    self.time === 0 ||
                                    value.latency < self.time
                                ) {
                                    self.time = value.latency;
                                    self.rpc = rpc;
                                }
                            }
                        });
                    }
                }
            }
        },
    },
    watch: {
        $route(to) {
            document.title = to.meta.title
                ? `UCON | ${to.meta.title}`
                : 'UCON Marketplace';
            this.isOpenNavigation = false;
            window.scrollTo(0, 0);

            this.onLoad();

            // simulation loading assets
            setTimeout(() => {
                this.outLoad();
            }, 800);
        },
        isLogin: {
            handler(newVal) {
                if (newVal) {
                    this.getInfoUser();
                }
            },
        },
    },
    created() {
        this.$store.state.contract.web3.provider = new Web3(
            this.$store.state.contract.provider.link,
        );

        const userRaw = this.$store.getters['auth/loggedIn'];
        if (userRaw !== undefined && userRaw !== null) {
            this.$store.dispatch('info/req_getInfo');
            this.$store.dispatch('core/reqGetWalletConfig');
        }

        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'auth/REGISTER_SUCCESS':
                case 'auth/FORGOT_SUCCESS':
                case 'auth/LOGOUT_SUCCESS':
                    this.outLoad();
                    this.$router.push({ name: 'Login' });
                    break;
                case 'auth/LOGIN_SUCCESS':
                    this.$toastr.s('Login Success', 'Successfully');
                    this.onLoad();
                    setTimeout(() => {
                        this.$store.dispatch('info/req_getInfo');
                        if (this.$route.name === 'Login') {
                            this.$router.push({
                                name: 'Member',
                            });
                        }
                        this.outLoad();
                    }, 1000);

                    break;
                case 'info/GET_INFO_SUCCESS':
                    this.outLoad();
                    break;
                case 'core/SUCCESS_MESSAGE':
                    this.outLoad();
                    this.$toastr.s(
                        this.$store.state.core.success,
                        'Successfully',
                    );

                    break;
                case 'core/ERROR_MESSAGE':
                    this.outLoad();
                    this.$toastr.e(this.$store.state.core.error, 'Oops!');

                    break;
                case 'core/ERROR_500':
                    this.outLoad();
                    this.s500 = true;

                    break;

                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        document.title = 'UCON Marketplace';
        this.unsubscribe();
    },
};
</script>
<style scoped>
html {
    scroll-behavior: smooth;
}
</style>

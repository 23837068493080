import Vue from 'vue';
import store from '@/store';
import { BootstrapVue } from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import VueToastr from 'vue-toastr';
import VueCookies from 'vue-cookies';
import moment from 'moment';

// eslint-disable-next-line import/no-extraneous-dependencies
import dotenv from 'dotenv';
import App from './App.vue';
import router from './router';
import './design/scss/app.scss';

import './design/scss/main.scss';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cross-env';

dotenv.config({ path: `.env.${process.env.NODE_ENV}` });

require('vue-tour/dist/vue-tour.css');
const numeral = require('numeral');

Vue.use(VueClipboard);
VueClipboard.config.autoSetContainer = true;
Vue.use(BootstrapVue);
Vue.use(VueCookies);

Vue.use(VueToastr, {
    defaultTimeout: 3000,
    defaultPosition: 'toast-top-right',
    defaultClassNames: ['animate__animated ', 'animate__bounceInRight'],
});

Vue.mixin({
    methods: {
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        TruncateToDecimals2(num, type, dec = 2) {
            //   if (!dec) dec = 2;
            if (num === undefined) {
                return 0;
            }
            const numberFormat = parseFloat(num.toFixed(10));
            const d = numberFormat.toString().split('.');
            if (type === 1) {
                return `${numeral(d[0]).format(0, 0)}`;
            }
            return `${numeral(d[0]).format(0, 0)}${
                d[1] ? `.${d[1].slice(0, dec)}` : ''
            }`;
        },
        getDateTime(unix) {
            return moment(unix)
                .utcOffset('+00:00')
                .format('DD/MM/YYYY, HH:mm:ss');
        },
        getDateTime3(date) {
            const unix = Date.parse(date);
            return moment(unix)
                .utcOffset('+00:00')
                .format('DD/MM/YYYY, HH:mm:ss');
        },
        getDateTime4(unix) {
            return moment(unix * 1000)
                .utcOffset('+00:00')
                .format('DD/MM/YYYY');
        },
        messageErrorToastr(message) {
            this.$toastr.e(
                message.replace('Returned error: execution reverted: ', ''),
                'Failed',
            );
        },
        isNaN(x) {
            // eslint-disable-next-line no-self-compare
            return x !== x;
        },
    },
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
